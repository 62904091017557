<template>
  <div
    class="flex items-center justify-center w-4 h-4 border-2 border-red-500 rounded-full"
  >
    <div :class="selected && 'bg-red-500'" class="w-2 h-2 rounded-full" />
  </div>
</template>

<script>
export default {
  props: ["selected"],
};
</script>
