<template>
  <button
    class="px-4 py-2 text-sm transition-colors duration-150 rounded-md focus:outline-none"
    :class="{
      'bg-red-500 hover:bg-red-600 text-white': primary,
      'bg-gray-200 hover:bg-gray-300 text-gray-800': secondary,
    }"
    :type="!submit && 'button'"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    primary: Boolean,
    secondary: Boolean,
    submit: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
